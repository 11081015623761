"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
require("../models/rate-total");
const period_rate_totals_1 = require("../models/period-rate-totals");
const i0 = require("@angular/core");
const i1 = require("@angular/common");
function RateTotalComponent_span_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 3);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "number");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1("", i0.ɵɵpipeBind2(2, 1, ctx_r0.percentage, "1.0-4"), "%");
} }
function RateTotalComponent_span_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 4);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "number");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1("$", i0.ɵɵpipeBind2(2, 1, ctx_r1.dollar, "1.2-4"), "");
} }
/**
 * Calculates and displays the total value for the DMF rate schedule.
 *
 * The DMF rate schedule will could be dollar or/and percentage value. All the logic to calculate and format the total is centralised in this component.
 */
class RateTotalComponent {
    /**
     * Initializes a new instance of the RateTotalComponent class.
     */
    constructor() {
        /**
         * The dollar value of the calculated total.
         */
        this.dollar = null;
        /**
         * The precentage value of the calculated total.
         */
        this.percentage = null;
    }
    /**
     * @inheritDoc
     */
    ngOnChanges(changes) {
        let total;
        if (this.upto) {
            total = period_rate_totals_1.periodRateLineRunningTotal(this.upto, this.periodRates);
        }
        else {
            total = period_rate_totals_1.periodRateTotals(this.periodRates);
        }
        this.dollar = total.dollar;
        this.percentage = total.percentage;
    }
}
exports.RateTotalComponent = RateTotalComponent;
RateTotalComponent.ɵfac = function RateTotalComponent_Factory(t) { return new (t || RateTotalComponent)(); };
RateTotalComponent.ɵcmp = i0.ɵɵdefineComponent({ type: RateTotalComponent, selectors: [["rate-total"]], inputs: { upto: "upto", periodRates: "periodRates", detectedChangeRef: "detectedChangeRef" }, features: [i0.ɵɵNgOnChangesFeature], decls: 3, vars: 2, consts: [[1, "rate-total"], ["class", "rate-total-percentage", 4, "ngIf"], ["class", "rate-total-dollar", 4, "ngIf"], [1, "rate-total-percentage"], [1, "rate-total-dollar"]], template: function RateTotalComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, RateTotalComponent_span_1_Template, 3, 4, "span", 1);
        i0.ɵɵtemplate(2, RateTotalComponent_span_2_Template, 3, 4, "span", 2);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.percentage !== null);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.dollar !== null);
    } }, directives: [i1.NgIf], pipes: [i1.DecimalPipe], styles: [".rate-total[_ngcontent-%COMP%]   .rate-total-percentage[_ngcontent-%COMP%]    ~ .rate-total-dollar[_ngcontent-%COMP%]::before {\n  content: \"\\00a0 +\\00a0\";\n}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(RateTotalComponent, [{
        type: core_1.Component,
        args: [{
                selector: "rate-total",
                templateUrl: "./rate-total.component.html",
                styleUrls: ["./rate-total.component.less"],
            }]
    }], function () { return []; }, { upto: [{
            type: core_1.Input
        }], periodRates: [{
            type: core_1.Input
        }], detectedChangeRef: [{
            type: core_1.Input
        }] }); })();
