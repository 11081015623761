"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const dnaR_dropdown_multiSelect_component_1 = require("./dnaR-dropdown-multiSelect.component");
const i0 = require("@angular/core");
const i1 = require("./dnaR-dropdown-multiSelect.component");
const i2 = require("@angular/common");
function ListItem_input_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "input", 3);
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("checked", ctx_r0.selected)("disabled", ctx_r0.disabled);
} }
const _c0 = function (a0, a1, a2) { return { "multiselect-item-checkbox": a0, "item-header": a1, "is-selected-item": a2 }; };
const _c1 = ["*"];
let uniqueListItemId = 0;
class ListItem {
    constructor(parent) {
        this.isHeader = false;
        this.disabled = false;
        // consider including item limit reached as part of disabled getter
        this.selected = false;
        this.singleSelection = false;
        /**
         * Event emitted when the list item is clicked.
         */
        this.click = new core_1.EventEmitter();
        this.uniqueId = `list-item-${uniqueListItemId++}`;
        this.singleSelection = parent.singleSelection;
    }
    onClick() {
        this.click.emit(this.value);
    }
}
exports.ListItem = ListItem;
ListItem.ɵfac = function ListItem_Factory(t) { return new (t || ListItem)(i0.ɵɵdirectiveInject(dnaR_dropdown_multiSelect_component_1.DnaRDropdownMultiSelect)); };
ListItem.ɵcmp = i0.ɵɵdefineComponent({ type: ListItem, selectors: [["list-item"]], inputs: { value: "value", uniqueId: "uniqueId", isHeader: "isHeader", disabled: "disabled", selected: "selected" }, outputs: { click: "click" }, ngContentSelectors: _c1, decls: 4, vars: 7, consts: [[1, "listitem", 3, "id", "ngClass", "click"], ["type", "checkbox", "aria-label", "multiselect-item", 3, "checked", "disabled", 4, "ngIf"], ["id", "itemText"], ["type", "checkbox", "aria-label", "multiselect-item", 3, "checked", "disabled"]], template: function ListItem_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵlistener("click", function ListItem_Template_div_click_0_listener($event) { $event.stopPropagation(); return ctx.onClick(); });
        i0.ɵɵtemplate(1, ListItem_input_1_Template, 1, 2, "input", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵprojection(3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵpropertyInterpolate("id", ctx.uniqueId);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(3, _c0, !ctx.isHeader, ctx.isHeader, ctx.selected));
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.isHeader && !ctx.singleSelection);
    } }, directives: [i2.NgClass, i2.NgIf], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ListItem, [{
        type: core_1.Component,
        args: [{
                selector: 'list-item',
                templateUrl: './list-item.component.html'
            }]
    }], function () { return [{ type: i1.DnaRDropdownMultiSelect, decorators: [{
                type: core_1.Inject,
                args: [dnaR_dropdown_multiSelect_component_1.DnaRDropdownMultiSelect]
            }] }]; }, { value: [{
            type: core_1.Input
        }], uniqueId: [{
            type: core_1.Input
        }], isHeader: [{
            type: core_1.Input
        }], disabled: [{
            type: core_1.Input
        }], selected: [{
            type: core_1.Input
        }], click: [{
            type: core_1.Output
        }] }); })();
