"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const rxjs_1 = require("rxjs");
const datepicker_1 = require("@angular/material/datepicker");
const core_2 = require("@angular/material/core");
const operators_1 = require("rxjs/operators");
const dnaR_datePicker_model_1 = require("./dnaR-datePicker.model");
const list_item_internal_1 = require("../dnaR-dropdown-multiSelect/list-item-internal");
const i0 = require("@angular/core");
const i1 = require("@angular/material/datepicker");
const i2 = require("@angular/material/core");
const i3 = require("../dnaR-dropdown-multiSelect/dnaR-dropdown-multiSelect.component");
const i4 = require("@angular/common");
class DnaRDatePickerHeaderComponent {
    constructor(_calendar, _dateAdapter, cdr) {
        this._calendar = _calendar;
        this._dateAdapter = _dateAdapter;
        this._destroyed = new rxjs_1.Subject();
        this.dropdownSettings = {
            singleSelection: true,
            idField: 'id',
            textField: 'name',
            itemsShowLimit: 1,
            addAllOption: false,
            enableCheckAll: false,
            showClearSelectedItems: false,
            closeDropDownOnSelection: true,
            scrollToSelection: true
        };
        this._monthsList = dnaR_datePicker_model_1.MonthsList;
        this.monthsDropDownList = [];
        this.selectedMonth$ = new rxjs_1.BehaviorSubject(undefined);
        this.yearsDropDownList = [];
        this.selectedYear$ = new rxjs_1.BehaviorSubject(undefined);
        this.currentDate = new Date();
        this.settings = dnaR_datePicker_model_1.DefaultSettings;
        _calendar.stateChanges
            .pipe(operators_1.takeUntil(this._destroyed))
            .subscribe(() => cdr.markForCheck());
    }
    ngOnInit() {
        this.currentDate = (this._calendar.activeDate !== null)
            ? new Date(this._dateAdapter.getYear(this._calendar.activeDate), this._dateAdapter.getMonth(this._calendar.activeDate), this._dateAdapter.getDate(this._calendar.activeDate))
            : new Date();
        this.populateDropDownLists();
        this.setSelected();
    }
    setSelected() {
        const selectedMonth = this.currentDate.getMonth();
        this.selectedMonth$.next(selectedMonth);
        const selectedYear = this.currentDate.getFullYear();
        this.selectedYear$.next(selectedYear);
    }
    populateDropDownLists() {
        // populate months list
        this._monthsList.forEach((month, i) => {
            this.monthsDropDownList.push({ id: i, name: month });
        });
        // populate years list
        const currentYear = new Date().getFullYear();
        const startYear = currentYear - this.settings.yearsBackTo;
        const endYear = currentYear + this.settings.yearsUpTo;
        const selectedYear = this.currentDate.getFullYear();
        if (selectedYear < startYear) {
            this.yearsDropDownList.push({ id: selectedYear, name: selectedYear.toString() });
        }
        for (let i = startYear; i <= endYear; i++) {
            this.yearsDropDownList.push({ id: i, name: i.toString() });
        }
        if (selectedYear > endYear) {
            this.yearsDropDownList.push({ id: selectedYear, name: selectedYear.toString() });
        }
    }
    ngOnDestroy() {
        this._destroyed.next();
        this._destroyed.complete();
    }
    previousClicked(mode) {
        if (mode === 'month') {
            const currentMonthValue = this.selectedMonth$.getValue();
            var monthValue = currentMonthValue - 1;
            const lastMonthInTheList = this._monthsList.indexOf("Dec");
            var monthValue = (monthValue < 0) ? lastMonthInTheList : monthValue;
            this.selectedMonth$.next(monthValue);
            this.monthSelect([new list_item_internal_1.ListItemInternal(monthValue, "")]);
        }
        if (mode === 'year') {
            const currentYearValue = this.selectedYear$.getValue();
            const currentYearItem = this.yearsDropDownList.find(x => x.id == currentYearValue);
            let nextIndex = this.yearsDropDownList.indexOf(currentYearItem) - 1;
            nextIndex = (nextIndex < 0) ? this.yearsDropDownList.length - 1 : nextIndex;
            const nextAvailableYearItem = this.yearsDropDownList[nextIndex];
            this.selectedYear$.next(nextAvailableYearItem.value);
            this.yearSelect([new list_item_internal_1.ListItemInternal(nextAvailableYearItem.id, "")]);
        }
    }
    nextClicked(mode) {
        if (mode === 'month') {
            const currentMonthValue = this.selectedMonth$.getValue();
            var monthValue = currentMonthValue + 1;
            const indexOfLAstMonthInTheList = this._monthsList.indexOf("Dec");
            const firstMonthInTheList = this._monthsList.indexOf("Jan");
            var monthValue = (monthValue > indexOfLAstMonthInTheList) ? firstMonthInTheList : monthValue;
            this.selectedMonth$.next(monthValue);
            this.monthSelect([new list_item_internal_1.ListItemInternal(monthValue, "")]);
        }
        if (mode === 'year') {
            const currentYearValue = this.selectedYear$.getValue();
            const currentYearItem = this.yearsDropDownList.find(x => x.id == currentYearValue);
            let nextIndex = this.yearsDropDownList.indexOf(currentYearItem) + 1;
            nextIndex = (nextIndex >= this.yearsDropDownList.length) ? 0 : nextIndex; // if beyond list then start all over 
            const nextAvailableYearItem = this.yearsDropDownList[nextIndex];
            this.selectedYear$.next(nextAvailableYearItem.value);
            this.yearSelect([new list_item_internal_1.ListItemInternal(nextAvailableYearItem.id, "")]);
        }
    }
    monthSelect(selectedItems) {
        const currentMonth = this._dateAdapter.getMonth(this._calendar.activeDate);
        const selectedMonth = selectedItems[0].value;
        this.selectedMonth$.next(selectedMonth);
        const jump = selectedMonth - currentMonth;
        this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, jump);
    }
    yearSelect(selectedItems) {
        const currentYear = this._dateAdapter.getYear(this._calendar.activeDate);
        const selectedYear = selectedItems[0].value;
        this.selectedYear$.next(selectedYear);
        const jump = selectedYear - currentYear;
        this._calendar.activeDate = this._dateAdapter.addCalendarYears(this._calendar.activeDate, jump);
    }
}
exports.DnaRDatePickerHeaderComponent = DnaRDatePickerHeaderComponent;
DnaRDatePickerHeaderComponent.ɵfac = function DnaRDatePickerHeaderComponent_Factory(t) { return new (t || DnaRDatePickerHeaderComponent)(i0.ɵɵdirectiveInject(i1.MatCalendar), i0.ɵɵdirectiveInject(i2.DateAdapter), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef)); };
DnaRDatePickerHeaderComponent.ɵcmp = i0.ɵɵdefineComponent({ type: DnaRDatePickerHeaderComponent, selectors: [["dnaR-date-picker-header"]], features: [i0.ɵɵProvidersFeature([
            { provide: core_2.MAT_DATE_FORMATS, useValue: dnaR_datePicker_model_1.DATEPICKER_FORMATS },
        ])], decls: 22, vars: 12, consts: [[1, "col-xs-12", "noSidePadding", "date-picker-header"], ["datePickerHeader", ""], [1, "col-xs-6", "noSidePadding", "navigate-month"], [1, "col-xs-3", "nextNav"], [1, "back-month", "pull-right", 3, "click"], [1, "icon", "icon-chevronLeft-outline-fill", "icon-primary-t2", "pull-right"], [1, "col-xs-6", "noSidePadding"], ["name", "monthsList", "data-automation", "monthsList", 1, "date-picker-dropdown", 3, "placeholder", "settings", "data", "value", "onSelectionChange"], [1, "col-xs-3", "backNav"], [1, "next-month", "pull-left", 3, "click"], [1, "icon", "icon-chevronRight-outline-fill", "icon-primary-t2", "pull-left"], [1, "col-xs-6", "noSidePadding", "navigate-year"], [1, "col-xs-2", "backNav"], [1, "back-year", "pull-right", 3, "click"], [1, "col-xs-7", "noSidePadding"], ["name", "yearsList", "data-automation", "yearsList", 1, "date-picker-dropdown", 3, "placeholder", "settings", "data", "value", "onSelectionChange"], [1, "col-xs-2", "nextNav"], [1, "next-year", "pull-left", 3, "click"]], template: function DnaRDatePickerHeaderComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0, 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "div", 3);
        i0.ɵɵelementStart(4, "a", 4);
        i0.ɵɵlistener("click", function DnaRDatePickerHeaderComponent_Template_a_click_4_listener() { return ctx.previousClicked("month"); });
        i0.ɵɵelement(5, "span", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(6, "div", 6);
        i0.ɵɵelementStart(7, "dnaR-dropdown-multiSelect", 7);
        i0.ɵɵlistener("onSelectionChange", function DnaRDatePickerHeaderComponent_Template_dnaR_dropdown_multiSelect_onSelectionChange_7_listener($event) { return ctx.monthSelect($event); });
        i0.ɵɵpipe(8, "async");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(9, "div", 8);
        i0.ɵɵelementStart(10, "a", 9);
        i0.ɵɵlistener("click", function DnaRDatePickerHeaderComponent_Template_a_click_10_listener() { return ctx.nextClicked("month"); });
        i0.ɵɵelement(11, "span", 10);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(12, "div", 11);
        i0.ɵɵelementStart(13, "div", 12);
        i0.ɵɵelementStart(14, "a", 13);
        i0.ɵɵlistener("click", function DnaRDatePickerHeaderComponent_Template_a_click_14_listener() { return ctx.previousClicked("year"); });
        i0.ɵɵelement(15, "span", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(16, "div", 14);
        i0.ɵɵelementStart(17, "dnaR-dropdown-multiSelect", 15);
        i0.ɵɵlistener("onSelectionChange", function DnaRDatePickerHeaderComponent_Template_dnaR_dropdown_multiSelect_onSelectionChange_17_listener($event) { return ctx.yearSelect($event); });
        i0.ɵɵpipe(18, "async");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(19, "div", 16);
        i0.ɵɵelementStart(20, "a", 17);
        i0.ɵɵlistener("click", function DnaRDatePickerHeaderComponent_Template_a_click_20_listener() { return ctx.nextClicked("year"); });
        i0.ɵɵelement(21, "span", 10);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(7);
        i0.ɵɵproperty("placeholder", "Select month")("settings", ctx.dropdownSettings)("data", ctx.monthsDropDownList)("value", i0.ɵɵpipeBind1(8, 8, ctx.selectedMonth$));
        i0.ɵɵadvance(10);
        i0.ɵɵproperty("placeholder", "Select year")("settings", ctx.dropdownSettings)("data", ctx.yearsDropDownList)("value", i0.ɵɵpipeBind1(18, 10, ctx.selectedYear$));
    } }, directives: [i3.DnaRDropdownMultiSelect], pipes: [i4.AsyncPipe], styles: [".date-picker-header[_ngcontent-%COMP%] {\n  width: 310px;\n  display: block;\n  padding: 25px 10px;\n  margin: 0px !important;\n}\n.nextNav[_ngcontent-%COMP%] {\n  padding-left: 3px !important;\n  padding-right: 3px !important;\n  display: inline-block;\n  height: 32px;\n}\n.nextNav[_ngcontent-%COMP%]   a[_ngcontent-%COMP%] {\n  display: inline-block;\n  width: 20px;\n  height: 32px;\n  cursor: pointer;\n}\n.nextNav[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]   span[_ngcontent-%COMP%] {\n  margin-top: 5px;\n}\n.backNav[_ngcontent-%COMP%] {\n  padding-left: 3px !important;\n  padding-right: 3px !important;\n  display: inline-block;\n  height: 32px;\n}\n.backNav[_ngcontent-%COMP%]   a[_ngcontent-%COMP%] {\n  display: inline-block;\n  width: 20px;\n  height: 32px;\n  cursor: pointer;\n}\n.backNav[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]   span[_ngcontent-%COMP%] {\n  margin-top: 5px;\n}\n   .date-picker-dropdown .multiselect-dropdown .selectedItems {\n  width: 50%!important;\n}\n   .date-picker-dropdown .dropdown-list {\n  width: auto !important;\n}\n   .date-picker-dropdown .multiselect-dropdown .dropdown-btn {\n  border: none !important;\n}\n   .date-picker-dropdown .multiselect-dropdown .dropdown-btn .toggle-arrow {\n  border: none !important;\n}\n   .date-picker-dropdown .multiselect-dropdown .dropdown-btn .toggle-arrow .dropdown-up {\n  border-bottom: 6px solid #008381 !important;\n}\n   .date-picker-dropdown .multiselect-dropdown .dropdown-btn .toggle-arrow .dropdown-down {\n  border-top: 6px solid #008381 !important;\n}\n   .date-picker-dropdown .multiselect-dropdown .dropdown-btn .selected-item-notab {\n  color: #008381 !important;\n  font-size: 16px;\n  font-weight: 600;\n}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DnaRDatePickerHeaderComponent, [{
        type: core_1.Component,
        args: [{
                selector: 'dnaR-date-picker-header',
                templateUrl: './dnaR-datePicker-header.component.html',
                styleUrls: ['./dnaR-datePicker-header.component.less'],
                providers: [
                    { provide: core_2.MAT_DATE_FORMATS, useValue: dnaR_datePicker_model_1.DATEPICKER_FORMATS },
                ]
            }]
    }], function () { return [{ type: i1.MatCalendar }, { type: i2.DateAdapter }, { type: i0.ChangeDetectorRef }]; }, null); })();
