"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ValuesWithEffectiveDateDto {
}
exports.ValuesWithEffectiveDateDto = ValuesWithEffectiveDateDto;
var ValuesWithEffectiveDateValueType;
(function (ValuesWithEffectiveDateValueType) {
    ValuesWithEffectiveDateValueType[ValuesWithEffectiveDateValueType["FacilitySupportedResidentThreshold"] = 1] = "FacilitySupportedResidentThreshold";
    ValuesWithEffectiveDateValueType[ValuesWithEffectiveDateValueType["Facility12MonthRespiteDayAllocation"] = 2] = "Facility12MonthRespiteDayAllocation";
    ValuesWithEffectiveDateValueType[ValuesWithEffectiveDateValueType["FacilityRegisteredBeds"] = 3] = "FacilityRegisteredBeds";
    ValuesWithEffectiveDateValueType[ValuesWithEffectiveDateValueType["OptionalServiceRates"] = 4] = "OptionalServiceRates";
    ValuesWithEffectiveDateValueType[ValuesWithEffectiveDateValueType["NumberOfUnits"] = 5] = "NumberOfUnits";
})(ValuesWithEffectiveDateValueType = exports.ValuesWithEffectiveDateValueType || (exports.ValuesWithEffectiveDateValueType = {}));
