"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const common_1 = require("@angular/common");
const forms_1 = require("@angular/forms");
const dnaR_dropdown_multiSelect_component_1 = require("./dnaR-dropdown-multiSelect.component");
const dnaR_dropdown_multiSelect_list_filter_pipe_1 = require("./dnaR-dropdown-multiSelect-list-filter.pipe");
const dnaR_spinner_module_1 = require("../dnaR-spinner/dnaR-spinner.module");
const shared_module_1 = require("../../shared.module");
const list_item_component_1 = require("./list-item.component");
const i0 = require("@angular/core");
const i1 = require("@angular/forms");
const i2 = require("@angular/common");
const i3 = require("../../directives/show-hide-password.directive");
const i4 = require("../dnaR-paginator/dnaR-paginator.component");
const i5 = require("../dnaR-back-to-top/dnaR-back-to-top.component");
const i6 = require("../../directives/dnaR-popover/dnaR-popover.directive");
const i7 = require("../dnaR-checkbox/dnaR-checkbox.component");
const i8 = require("../../directives/click-outside-directive");
const i9 = require("../dnaR-spinner/dnaR-spinner.component");
const i10 = require("../../pipes/enquiryAccessibilityRequirementDescription.pipe");
const i11 = require("../../pipes/nullToZero.pipe");
class DnaRDropdownMultiSelectModule {
    static forRoot() {
        return {
            ngModule: DnaRDropdownMultiSelectModule,
        };
    }
}
exports.DnaRDropdownMultiSelectModule = DnaRDropdownMultiSelectModule;
DnaRDropdownMultiSelectModule.ɵmod = i0.ɵɵdefineNgModule({ type: DnaRDropdownMultiSelectModule });
DnaRDropdownMultiSelectModule.ɵinj = i0.ɵɵdefineInjector({ factory: function DnaRDropdownMultiSelectModule_Factory(t) { return new (t || DnaRDropdownMultiSelectModule)(); }, imports: [[shared_module_1.SharedModule, common_1.CommonModule, forms_1.FormsModule, dnaR_spinner_module_1.DnaRSpinnerModule]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DnaRDropdownMultiSelectModule, { declarations: [dnaR_dropdown_multiSelect_component_1.DnaRDropdownMultiSelect, dnaR_dropdown_multiSelect_list_filter_pipe_1.DnaRDropdownMultiSelectFilter, list_item_component_1.ListItem], imports: [shared_module_1.SharedModule, common_1.CommonModule, forms_1.FormsModule, dnaR_spinner_module_1.DnaRSpinnerModule], exports: [dnaR_dropdown_multiSelect_component_1.DnaRDropdownMultiSelect, list_item_component_1.ListItem] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DnaRDropdownMultiSelectModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [shared_module_1.SharedModule, common_1.CommonModule, forms_1.FormsModule, dnaR_spinner_module_1.DnaRSpinnerModule],
                declarations: [dnaR_dropdown_multiSelect_component_1.DnaRDropdownMultiSelect, dnaR_dropdown_multiSelect_list_filter_pipe_1.DnaRDropdownMultiSelectFilter, list_item_component_1.ListItem],
                exports: [dnaR_dropdown_multiSelect_component_1.DnaRDropdownMultiSelect, list_item_component_1.ListItem],
            }]
    }], null, null); })();
i0.ɵɵsetComponentScope(dnaR_dropdown_multiSelect_component_1.DnaRDropdownMultiSelect, [i1.ɵangular_packages_forms_forms_y, i1.NgSelectOption, i1.ɵangular_packages_forms_forms_x, i1.DefaultValueAccessor, i1.NumberValueAccessor, i1.RangeValueAccessor, i1.CheckboxControlValueAccessor, i1.SelectControlValueAccessor, i1.SelectMultipleControlValueAccessor, i1.RadioControlValueAccessor, i1.NgControlStatus, i1.NgControlStatusGroup, i1.RequiredValidator, i1.MinLengthValidator, i1.MaxLengthValidator, i1.PatternValidator, i1.CheckboxRequiredValidator, i1.EmailValidator, i1.NgModel, i1.NgModelGroup, i1.NgForm, i2.NgClass, i2.NgComponentOutlet, i2.NgForOf, i2.NgIf, i2.NgTemplateOutlet, i2.NgStyle, i2.NgSwitch, i2.NgSwitchCase, i2.NgSwitchDefault, i2.NgPlural, i2.NgPluralCase, i3.ShowHidePasswordDirective, i4.DnaRPaginator, i5.DnaRBackToTop, i6.DnaRPopoverDirective, i7.DnaRCheckbox, i8.ClickOutsideDirective, i9.DnaRSpinner, dnaR_dropdown_multiSelect_component_1.DnaRDropdownMultiSelect, list_item_component_1.ListItem], [i2.AsyncPipe, i2.UpperCasePipe, i2.LowerCasePipe, i2.JsonPipe, i2.SlicePipe, i2.DecimalPipe, i2.PercentPipe, i2.TitleCasePipe, i2.CurrencyPipe, i2.DatePipe, i2.I18nPluralPipe, i2.I18nSelectPipe, i2.KeyValuePipe, i10.EnquiryAccessibilityRequirementDescription, i11.NullToZeroPipe, dnaR_dropdown_multiSelect_list_filter_pipe_1.DnaRDropdownMultiSelectFilter]);
