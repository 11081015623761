"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const i0 = require("@angular/core");
let uniqueListItemId = 0;
class ListItemInternal {
    constructor(value, text, isHeader = false) {
        this.isHeader = false;
        this.isDisabled = false;
        this.value = value;
        this.text = text;
        this.isHeader = isHeader;
        this.uniqueId = `list-item-internal-${uniqueListItemId++}`;
    }
    /**
     * @deprecated. Use @see value instead.
     */
    get id() {
        return this.value.toString();
    }
    set id(value) {
        this.value = value;
    }
}
exports.ListItemInternal = ListItemInternal;
ListItemInternal.ɵfac = function ListItemInternal_Factory(t) { i0.ɵɵinvalidFactory(); };
ListItemInternal.ɵdir = i0.ɵɵdefineDirective({ type: ListItemInternal, inputs: { value: "value", uniqueId: "uniqueId" } });
