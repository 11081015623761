"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const common_1 = require("@angular/common");
const values_with_effective_date_dto_1 = require("../dtos/values-with-effective-date-dto");
const i0 = require("@angular/core");
/**
 * Returns a dollar formatted string of the provided @see ValuesWithEffectiveDateDto value (based on the value type).
 *
 * @example
 * <span>{{optionalService | optionalServiceCurrentRatePipe | valuesWithEffectiveDateValue }}</span>
 */
class ValuesWithEffectiveDateValuePipe {
    /**
     * Initializes a new instance of the @see ValuesWithEffectiveDateValuePipe class.
     *
     * @param local The locale code for the locale formatting rules to use.
     */
    constructor(locale) {
        this.locale = locale;
    }
    /**
     * Returns a dollar formatted string of the provided @see ValuesWithEffectiveDateDto value (based on the value type).
     *
     * @param items The @see ValuesWithEffectiveDateDto value.
     * @returns The dollar formatted string of the value.
     */
    transform(valuesWithEffectiveDate) {
        if (valuesWithEffectiveDate && (valuesWithEffectiveDate.value != null || valuesWithEffectiveDate.value != undefined)) {
            // There is some inconsistencies with how enum values are emitted by the API, strings or numbers. This check cover both approaches.
            // Optional service rates
            if (valuesWithEffectiveDate.valueType === values_with_effective_date_dto_1.ValuesWithEffectiveDateValueType.OptionalServiceRates || valuesWithEffectiveDate.valueType === "OptionalServiceRates") {
                return `$${common_1.formatNumber(Number(valuesWithEffectiveDate.value), this.locale, "1.2-2")}`;
            }
            return valuesWithEffectiveDate.value;
        }
        return "";
    }
}
exports.ValuesWithEffectiveDateValuePipe = ValuesWithEffectiveDateValuePipe;
ValuesWithEffectiveDateValuePipe.ɵfac = function ValuesWithEffectiveDateValuePipe_Factory(t) { return new (t || ValuesWithEffectiveDateValuePipe)(i0.ɵɵdirectiveInject(core_1.LOCALE_ID)); };
ValuesWithEffectiveDateValuePipe.ɵpipe = i0.ɵɵdefinePipe({ name: "valuesWithEffectiveDateValue", type: ValuesWithEffectiveDateValuePipe, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ValuesWithEffectiveDateValuePipe, [{
        type: core_1.Pipe,
        args: [{ name: "valuesWithEffectiveDateValue" }]
    }], function () { return [{ type: undefined, decorators: [{
                type: core_1.Inject,
                args: [core_1.LOCALE_ID]
            }] }]; }, null); })();
